<template>
    <div class="ContentBoard">
        <div class="mainTitle textAverage">
            {{title}}
        </div>
        <div class="picArea">
            <img class="userImg" src="~@/assets/icons/user.svg">
        </div>
        <div class="form" :class="{formEditYouself: selfUser}">
            <div class="column" :class="{columnEditYouself: selfUser}">
                <div class="row">
                    <div class="colLabel" :class="{colLabelEditYouself: selfUser}">
                        <label class="textSmall sideFloat">Nome: </label>
                    </div>
                    <div class="colImput" :class="{colImputEditYouself: selfUser}">
                        <input class="input textSmall" type="text" v-model="name">
                        <span class="textErro inputMargin">{{errorMsgName}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="colLabel" :class="{colLabelEditYouself: selfUser}">
                        <label class="textSmall sideFloat">E-mail: </label>
                    </div>
                    <div class="colImput" :class="{colImputEditYouself: selfUser}">
                        <input class="input textSmall" :class="{inputDisabled: user}" :disabled="user" v-model="email" @blur="checkEmailUsed()">
                        <span class="textErro inputMargin">{{errorMsgEmail}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="colLabel" :class="{colLabelEditYouself: selfUser}">
                        <label class="textSmall sideFloat">Telefone: </label>
                    </div>
                    <div class="colImput" :class="{colImputEditYouself: selfUser}">
                        <input class="input textSmall" type="text" v-model="phone" @input="acceptPhone" @focus="clearPhone">
                        <span class="textErro inputMargin">{{errorMsgPhone}}</span>
                    </div>
                </div>
                <div v-if="user" class="row">
                    <template v-if="user.realmId != getCurrentUserId()">
                        <div class="colLabel colLabelKeepSize">
                            <label class="textSmall sideFloat">Bloqueado: </label>
                        </div>
                        <div class="colImput colImputKeepSize">
                            <label class="switch">
                                <input type="checkbox" v-model="blocked">
                                <span class="slider round switchWidth"></span>
                            </label>
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="!selfUser" class="column">
                <div class="row">
                    <div class="colLabel">
                        <label class="textSmall sideFloat">Permissão: </label>
                    </div>
                    <div class="colImput">
                        <div class="selectConfig textSmall" >
                            <div class="selectBtn">
                                <label>
                                    <select class="textSmall" v-model="registerAccessLevel">
                                        <option class="textSmall" value="8">Monitoramento</option>
                                        <option v-if="userAccessLevel <= accessLevel.DESENVOLVIMENTO || userAccessLevel == accessLevel.VENDAS" class="textSmall" value="7">Cliente</option>
                                        <option v-if="userAccessLevel <= accessLevel.DESENVOLVIMENTO || userAccessLevel == accessLevel.VENDAS" class="textSmall" value="6">Integrador</option>
                                        <option v-if="userAccessLevel <= accessLevel.DESENVOLVIMENTO" class="textSmall" value="5">Vendas</option>
                                        <option v-if="userAccessLevel <= accessLevel.DESENVOLVIMENTO" class="textSmall" value="4">Produção</option>
                                        <option v-if="userAccessLevel <= accessLevel.DESENVOLVIMENTO" class="textSmall" value="3">Suporte</option>
                                        <option v-if="userAccessLevel == accessLevel.DIRETOR" class="textSmall" value="2">Desenvolvimento</option>
                                        <option v-if="userAccessLevel == accessLevel.DIRETOR" class="textSmall" value="1">Diretor</option>
                                    </select>
                                    <span class="pointer">
                                        <img class="verySmallIconBtn" style="transform: rotate(90deg)" src="~@/assets/icons/arrowWhite.svg" alt="">
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="registerAccessLevel != accessLevel.PRODUCAO && registerAccessLevel != accessLevel.VENDAS" class="smallRow">
                    <div class="colLabel colLabelKeepSize">
                        <label class="textSmall sideFloat">Serviços: </label>
                    </div>
                    <div v-if="registerAccessLevel <= accessLevel.SUPORTE || registerAccessLevel == accessLevel.INTEGRADOR ||  registerAccessLevel == accessLevel.CLIENTE" class="colImput colImputKeepSize">
                        <label class="switch">
                            <input type="checkbox" v-model="rdpView" >
                            <span class="slider round switchWidth"></span>
                            <label class="textSmall labelSwitch">Visualização</label>
                        </label>
                    </div>
                </div>
                <div v-if="registerAccessLevel <= accessLevel.SUPORTE || registerAccessLevel == accessLevel.INTEGRADOR ||  registerAccessLevel == accessLevel.CLIENTE" class="smallRow">
                    <div class="colLabel colLabelKeepSize"></div>
                    <div class="colImput colImputKeepSize">
                        <label class="switch">
                            <input type="checkbox" v-model="rdp" >
                            <span class="slider round switchWidth"></span>
                            <label class="textSmall labelSwitch">Acesso Remoto</label>
                        </label>
                    </div>
                </div>
                <div v-if="registerAccessLevel <= accessLevel.SUPORTE || registerAccessLevel == accessLevel.INTEGRADOR ||  registerAccessLevel == accessLevel.CLIENTE" class="smallRow">
                    <div class="colLabel colLabelKeepSize"></div>
                    <div class="colImput colImputKeepSize">
                        <label class="switch">
                            <input type="checkbox" v-model="dt" >
                            <span class="slider round"></span>
                            <label class="textSmall labelSwitch">Transf. de arquivos</label>
                        </label>
                    </div>
                </div>
                <div v-if="registerAccessLevel != accessLevel.PRODUCAO && registerAccessLevel != accessLevel.VENDAS" class="smallRow">
                    <div class="colLabel colLabelKeepSize"></div>
                    <div class="colImput colImputKeepSize">
                        <label class="switch">
                            <input type="checkbox" v-model="dashboard" >
                            <span class="slider round switchWidth"></span>
                            <label class="textSmall labelSwitch">Painel</label>
                        </label>
                    </div>
                </div>
                <div v-if="registerAccessLevel <= accessLevel.DESENVOLVIMENTO" class="smallRow">
                    <div class="colLabel colLabelKeepSize"></div>
                    <div class="colImput colImputKeepSize">
                        <label class="switch">
                            <input type="checkbox" v-model="ssh" >
                            <span class="slider round switchWidth"></span>
                            <label class="textSmall labelSwitch">SSH</label>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="ResponsiveContentBoard"></div>
        <div class="ResponsiveBtnConfigArea">
            <button @click="save()" class="btn btnNormalSize btnTypeSave btnMargin">Salvar</button>
            <button @click="comeBack()" class="btn btnNormalSize btnTypeCancel btnMargin">Voltar</button>
        </div>
        <PopUp msg="Salvando Usuário" :show="loadData" type="Loader"/>
        <PopUp :msg="faultMsg" :show="faultOnUpdateUser" type="Fault" :onOk="popUpOk"/>
    </div>
</template>

<script>
    var letterNumber = /^[\w\-\s\.\ç\Ç]+$/
    var emailRegex =  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

    import PopUp from '@/views/PopUp/PopUp.vue'
    import UserDAO from '@/sys/Control/User/UserDAO'
    import BackEnd from '@/sys/BackEnd/BanckEnd'
    import {Services} from '@/sys/Model/Permissions/Services'
    import {AccessLevel} from '@/sys/Model/Permissions/AccessLevel'

    export default {
        components:{ PopUp },
        props:{
            user: Object,
            usersList: null,
            componet: Boolean
        },
        methods:{
            comeBack(){
                if(this.componet){
                    this.$emit('close')
                }else{
                    this.$router.push({path: '/Setting/Users'})
                }
            },
            getCurrentUserId(){
                return BackEnd.getCurrentUserId();
            },
            getCurrentUserAccess(){
                return BackEnd.getCurrentUserAccess();
            },
            async checkEmailUsed(){
                if(this.email != this.emailAux) {
                    let user = await UserDAO.readUser(this.email);
                    if(user) {
                        this.errorMsgEmail = 'Este e-mail já está em uso';
                        this.validEmail = false;
                    } else {
                        this.errorMsgEmail = '';
                        this.validEmail = true;
                    }
                } else {
                    this.errorMsgEmail = '';
                    this.validEmail = true;
                }
            },
            clearPhone(){
                if(this.phone.length == 16){
                    this.phone = ''
                }
            },
            acceptPhone() {
                var x = this.phone.replace(/\D/g, '').match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
                this.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            },
            async save(){
                if(this.name == ''){
                    this.errorMsgName = 'Preencha este campo'
                }

                if(this.phone == ''){
                    this.errorMsgPhone = ''
                }else{
                    if(this.phone.length < 15){
                        this.errorMsgPhone = 'Número inválido'
                    }else{
                        this.errorMsgPhone = ''
                    }
                }

                if(this.email == '') {
                    this.errorMsgEmail = 'Preencha este campo'
                }else {
                    if(this.email.length > 45) {
                        this.errorMsgEmail = 'Tamanho máximo de 45 caracteres'
                    }else {
                        if(this.email.match(emailRegex)) {
                            if(this.validEmail) {
                                this.errorMsgEmail = ''
                            }
                        }else {
                            this.errorMsgEmail = 'Formato inválido'
                        }
                    }
                }

                if(this.errorMsgName == '' && this.errorMsgPhone == '' && this.errorMsgEmail == ''){
                    this.loadData = true
                    if(this.phone != ''){
                        this.phone = '+55' + this.phone.replace(/\D/g, "")
                        if(this.phone.length != 14){
                            this.phone = this.phoneAux
                        }
                    }else{
                        this.phone = null
                    }
                    if(this.blocked) {
                        this.blockedAt = new Date();
                    }

                    if(this.registerAccessLevel != this.accessLevel.PRODUCAO && this.registerAccessLevel != this.accessLevel.VENDAS) {
                        this.permissions.services = [];
                        if(this.rdpView) {
                            this.permissions.services.push(Services.RDP_VIEW);
                        }
                        if(this.rdp) {
                            this.permissions.services.push(Services.RDP);
                        }
                        if(this.dt) {
                            this.permissions.services.push(Services.FTP);
                        }
                        if(this.dashboard) {
                            this.permissions.services.push(Services.DASHBOARD);
                        }
                        if(this.registerAccessLevel <= this.accessLevel.DESENVOLVIMENTO) {
                            if(this.ssh) {
                                this.permissions.services.push(Services.SSH);
                            }
                        }
                    }

                    if(this.user){
                        await UserDAO.updateUser({
                            _id: this.user._id,
                            name: this.name.trim(),
                            email: this.email,
                            phone: this.phone,
                            accessLevel: Number(this.registerAccessLevel),
                            permissions: this.permissions,
                            blockedAt: this.blockedAt,
                            createdAt: this.user.createdAt,
                            updatedAt: new Date(),
                            deletedAt: this.user.deletedAt
                        }, false).catch(e=>this.popUpException(e));
                    }else{
                        await UserDAO.createUser({
                            name: this.name.trim(),
                            realmId: null,
                            email: this.email,
                            phone: this.phone,
                            accessLevel: Number(this.registerAccessLevel),
                            permissions: this.permissions,
                            blockedAt: this.blockedAt,
                            createdAt: new Date(),
                            updatedAt: new Date(),
                            deletedAt: null
                        }).catch(e=>this.popUpException(e));
                    }
                    this.loadData = false
                    if(this.componet){
                        if(!this.faultOnUpdateUser) {
                            this.$emit('close');
                        }
                    }else{
                        this.$router.push({path: '/Setting/Users'});
                    }
                }
            },
            popUpOk: function(){
                this.faultOnUpdateUser=false;
                this.$emit('close');
            },
            popUpException: function(e){
                this.loadData = false;
                this.faultMsg=e;
                this.faultOnUpdateUser=true;
            }
        },
        watch:{
            name(){
                if(this.name.match(letterNumber)){
                    this.errorMsgName = ''
                }else{
                    this.errorMsgName = 'Caractere não permitido'
                }
                if(this.name == ''){
                    this.errorMsgName = 'Preencha este campo'
                }
                if(this.name.length > 20){
                    this.errorMsgName = 'Tamanho máximo de 20 caracteres'
                }
                if(!this.name.replace(/\s/g, '').length){
                    this.errorMsgName = 'Nome inválido'
                }
            },
            phone(){
                if(this.user){
                    if(this.errorMsgPhone != ''){
                        if(this.phone == ''){
                            this.errorMsgPhone = ''
                        }else{
                            if(this.phone.length < 15){
                                this.errorMsgPhone = 'Número inválido'
                            }else{
                                this.errorMsgPhone = ''
                            }
                        }
                    }
                }
            },
            email(){
                if(this.user){
                    if(this.errorMsgEmail != ''){
                        if(this.email == '') {
                            this.errorMsgEmail = 'Preencha este campo'
                        }else {
                            if(this.email.length > 45) {
                                this.errorMsgEmail = 'Tamanho máximo de 45 caracteres'
                            }else {
                                if(this.email.match(emailRegex)) {
                                    if(this.validEmail) {
                                        this.errorMsgEmail = ''
                                    }
                                }else {
                                    this.errorMsgEmail = 'Formato inválido'
                                }
                            }
                        }
                    }
                }
            }
        },
        async created(){
            if(this.user){
                this.title = 'Editar Usuário';
                this.name = this.user.name;

                if(this.user.realmId != this.getCurrentUserId()) {
                    this.classType = "column";
                }else {
                    this.selfUser = true;
                    this.classType = "columnCenter";
                }

                if(this.user.phone != null){
                    this.phone = '(XX) X XXXX-X' + this.user.phone.substring(11,14);
                    this.phoneAux = this.user.phone;
                }

                this.email = this.user.email;
                this.emailAux = this.email;

                if(this.user.blockedAt == null) {
                    this.blocked = false;
                } else {
                    this.blocked = true;
                }

                this.rdpView = false;
                this.rdp = false;
                this.dt = false;
                this.dashboard = false;
                this.ssh = false;
                for(let i=0;  i < this.user.permissions.services.length; i++) {
                    switch(this.user.permissions.services[i]) {
                        case Services.RDP_VIEW:
                            this.rdpView = true;
                            break;
                        case Services.RDP:
                            this.rdp = true;
                            break;
                        case Services.FTP:
                            this.dt = true;
                            break;
                        case Services.DASHBOARD:
                            this.dashboard = true;
                            break;
                        case Services.SSH:
                            this.ssh = true;
                            break;
                    }
                }
                this.registerAccessLevel = this.user.accessLevel;
                this.passwordPlaceholder = '(Sem Alteração)';
            }else{
                this.title = 'Novo Usuário';
            }
            this.userAccessLevel = this.getCurrentUserAccess();
        },
        data(){
            return{
                title: '',
                name: '',
                errorMsgName: '',
                registerAccessLevel: 8,
                userAccessLevel: 8,
                blocked: false,
                blockedAt: null,
                selfUser: false,
                classType: '',
                phone: '',
                phoneAux: '',
                errorMsgPhone: '',
                email: '',
                emailAux: '',
                errorMsgEmail: '',
                validEmail: false,
                passwordPlaceholder: '',
                editPhone: false,
                rdpView: true,
                rdp: true,
                dt: true,
                dashboard: true,
                ssh: true,
                permissions: {
                    system: [],
                    services: []
                },
                loadData: false,
                faultOnUpdateUser: false,
                faultMsg: null,
                accessLevel: AccessLevel
            }
        }
    }
</script>

<style scoped>
    .formEditSelf{
        width: 50%;
        margin-left: 28%;
    }
    .sideFloat{
        float: right;
    }
    .form{
        display: table;
        width: 80%;
        margin-left: 10%;
        margin-top: 20px
    }
    .column{
        float: left;
        width: 50%;
    }
    .row{
        width: 100%;
        height: 85px;
    }
    .smallRow{
        width: 100%;
        height: 43px;
    }
    .colLabel{
        width: 30%;
        float: left;
        padding: 5px 30px 5px 0;
        display: table-cell;
        vertical-align: top;
    }
    .colImput{
        position: relative;
        width: 70%;
        float: left;
        padding: 0 5px;
        display: table-cell;
        vertical-align: top;
        margin-bottom: 30px
    }
    .formEditYouself{
        width: 90%;
        margin-left: 5%;
    }
    .columnEditYouself{
        width: 100%;
    }
    .colLabelEditYouself{
        width: 35%;
    }
    .colImputEditYouself{
        width: 65%;
        margin-bottom: 40px
    }
    .ContentBoard{
        display: flex;
        flex-direction: column;
        width: calc(100%);
        height: calc(100%);
        background-color:#292d32;
        overflow-y: auto;
        overflow-x: unset;
    }
    .mainTitle{
        width: 100%-10px;
        margin-right: 10px;
    }
    .picArea{
        top: 10px;
        right: 0;
        left: 0;
        margin: 0 auto;
        min-width: 180px;
        min-height: 180px;
        border-radius: 50%;
        background-color: #1e2227;
        margin-top: 20px;
    }
    .userImg{
        margin-top: 25px;
        margin-left: 35px;
    }
    .inputMargin{
        margin: 3px 0 0 15px;
    }
    .inputDisabled{
        background: #757575;
        color: #cecece
    }
    .switchButton{
        display: flex;
        align-items: center;
        min-width: 350px;
        margin-top: 20px;
    }
    .switchWidth{
        min-width: 50px;
    }
    .labelSwitch{
        margin-left: 100px;
        line-height: 43px
    }
    .btnColumn{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
    }
    .btnRow{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .btnMargin{
        margin: 10px 10px 10px 6px;
    }
    @media(max-width: 1700px){
        .form{
            width: 90%;
            margin-left: 5%;
        }
        .column{
            width: 100%;
        }
        .colLabel{
            width: 35%;
        }
        .colImput{
            width: 65%;
            margin-bottom: 40px
        }
    }
    @media(max-width: 1000px){
        .colLabel{
            width: 28%;
        }
    }
    @media(max-width: 720px){
        .colLabel{
            width: 100%;
        }
        .colImput{
            width: 100%;
            margin-bottom: 20px
        }
        .colLabelKeepSize{
            width: 25%;
        }
        .colImputKeepSize{
            width: 75%;
            height: 25px;
        }
        .sideFloat{
            float: left
        }
        .smallRow{
            height: 25px;
        }
        .labelSwitch{
            margin-left: 70px;
            line-height: 25px;
        }
    }
</style>